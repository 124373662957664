import React, { useState, useCallback } from 'react';
import { copyToClipboard } from '../../utils/noteFormatUtils';

const DocumentCopyButton = ({ document, currentPreset }) => {
    const [copied, setCopied] = useState(false);

    const handleCopyDocument = useCallback(async () => {
        const documentContent = document.content || '';
        const documentTitle = document.title || 'Untitled Document';
        const textToCopy = `${documentTitle}\n\n\n${documentContent}`;
        
        try {
            await copyToClipboard(textToCopy, true);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        } catch (err) {
            console.error('Failed to copy document: ', err);
        }
    }, [document]);

    return (
        <button
            onClick={handleCopyDocument}
            className={`flex items-center justify-center px-3 py-1.5 rounded-md ${
                copied 
                    ? 'bg-[#1976d2] bg-opacity-20 border-[#1976d2]' 
                    : 'base-button'
            } border focus:outline-none focus:ring-1 focus:ring-white transition duration-150 ease-in-out flash-button`}
            title="Copy Document"
        >
            <span className="material-symbols-outlined text-white text-base mr-1">
                {copied ? 'check_circle' : 'file_copy'}
            </span>
            <span className="text-xs text-[#e0e0e0] whitespace-nowrap hidden sm:inline">Copy</span>
            <span className="text-xs text-[#e0e0e0] whitespace-nowrap sm:hidden">Copy</span>
        </button>
    );
};

export default DocumentCopyButton; 