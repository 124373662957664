import React, { useState, useRef, useEffect } from 'react';
import ScribePushToNote from './button/ScribePushtoNote';
import apiService from '../../../services/api';

const Scribe = ({ currentPreset, updateNote, note }) => {
    const [isRecording, setIsRecording] = useState(false);
    const [transcription, setTranscription] = useState(note?.conversation || '');
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const wakeLockRef = useRef(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const audioContextRef = useRef(null);
    const chunkIntervalRef = useRef(null);
    const CHUNK_INTERVAL = 30000; // Process every 30 seconds
    const [recordingTime, setRecordingTime] = useState(0);
    const recordingTimerRef = useRef(null);

    useEffect(() => {
        if (isRecording) {
            recordingTimerRef.current = setInterval(() => {
                setRecordingTime(prev => prev + 1);
            }, 1000);
        } else {
            if (recordingTimerRef.current) {
                clearInterval(recordingTimerRef.current);
                recordingTimerRef.current = null;
            }
            setRecordingTime(0);
        }

        return () => {
            if (recordingTimerRef.current) {
                clearInterval(recordingTimerRef.current);
                recordingTimerRef.current = null;
            }
        };
    }, [isRecording]);

    useEffect(() => {
        return () => {
            if (mediaRecorderRef.current && isRecording) {
                mediaRecorderRef.current.stop();
            }
            if (chunkIntervalRef.current) {
                clearInterval(chunkIntervalRef.current);
            }
            if (recordingTimerRef.current) {
                clearInterval(recordingTimerRef.current);
            }
            releaseWakeLock();
        };
    }, [isRecording]);

    const handleTranscriptionUpdate = (newTranscription) => {
        if (typeof newTranscription === 'string') {
            setTranscription(newTranscription);
            updateNote("conversation", newTranscription);
            return;
        }
        
        setTranscription(prev => {
            const updatedTranscription = typeof newTranscription === 'function' 
                ? newTranscription(prev)
                : newTranscription;
            updateNote("conversation", updatedTranscription);
            return updatedTranscription;
        });
    };

    const acquireWakeLock = async () => {
        if ('wakeLock' in navigator) {
            try {
                wakeLockRef.current = await navigator.wakeLock.request('screen');
                
                audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
                const oscillator = audioContextRef.current.createOscillator();
                const gainNode = audioContextRef.current.createGain();
                gainNode.gain.value = 0;
                oscillator.connect(gainNode);
                gainNode.connect(audioContextRef.current.destination);
                oscillator.start();

                if ('permissions' in navigator) {
                    try {
                        await navigator.permissions.request({
                            name: 'background-fetch',
                        });
                    } catch (err) {
                        console.error('Background fetch permission not available');
                    }
                }
            } catch (err) {
                console.error('Failed to acquire Wake Lock:', err);
            }
        }
    };

    const releaseWakeLock = () => {
        if (wakeLockRef.current) {
            wakeLockRef.current.release()
                .then(() => {
                    wakeLockRef.current = null;
                })
                .catch((err) => {
                    console.error('Failed to release Wake Lock:', err);
                });
        }
        
        if (audioContextRef.current) {
            audioContextRef.current.close();
            audioContextRef.current = null;
        }
    };

    const processAudioChunk = async () => {
        if (audioChunksRef.current.length === 0) return;

        try {
            setIsProcessing(true);
            const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
            
            const response = await apiService.transcribeAudio(audioBlob);
            
            if (response?.text) {
                const newTranscription = response.text.trim() + '\n';
                const updatedTranscription = transcription ? `${transcription}${newTranscription}` : newTranscription;
                
                setTranscription(updatedTranscription);
                updateNote('conversation', updatedTranscription);
                
                audioChunksRef.current = [];
            }
        } catch (error) {
            console.error('Error transcribing audio chunk:', error);
        } finally {
            setIsProcessing(false);
        }
    };

    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorderRef.current = new MediaRecorder(stream, {
                mimeType: 'audio/webm;codecs=opus'
            });
            audioChunksRef.current = [];

            mediaRecorderRef.current.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    audioChunksRef.current.push(event.data);
                }
            };

            // Process chunks every 30 seconds
            const processChunks = async () => {
                if (!isRecording || !audioChunksRef.current.length) return;
                await processAudioChunk();
            };

            // Initial delay to allow some audio to accumulate
            setTimeout(() => {
                if (isRecording) {
                    processChunks();
                    chunkIntervalRef.current = setInterval(processChunks, CHUNK_INTERVAL);
                }
            }, 5000);

            mediaRecorderRef.current.onstop = async () => {
                // Process any remaining audio
                if (audioChunksRef.current.length > 0) {
                    await processAudioChunk();
                }

                // Clean up
                if (chunkIntervalRef.current) {
                    clearInterval(chunkIntervalRef.current);
                    chunkIntervalRef.current = null;
                }
                stream.getTracks().forEach(track => track.stop());
            };

            // Start recording in smaller chunks
            mediaRecorderRef.current.start(1000); // Get data every second
            setIsRecording(true);
            acquireWakeLock();
            window.dispatchEvent(new CustomEvent('recordingStateChange', { 
                detail: { isRecording: true } 
            }));
        } catch (error) {
            console.error('Error starting recording:', error);
            setIsRecording(false);
            if (chunkIntervalRef.current) {
                clearInterval(chunkIntervalRef.current);
                chunkIntervalRef.current = null;
            }
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current && isRecording) {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
            if (recordingTimerRef.current) {
                clearInterval(recordingTimerRef.current);
            }
            if (chunkIntervalRef.current) {
                clearInterval(chunkIntervalRef.current);
                chunkIntervalRef.current = null;
            }
            setRecordingTime(0);
            releaseWakeLock();
            window.dispatchEvent(new CustomEvent('recordingStateChange', { 
                detail: { isRecording: false } 
            }));
        }
    };

    const toggleRecording = () => {
        if (isRecording) {
            stopRecording();
        } else {
            startRecording();
        }
    };

    return (
        <div className="space-y-4">
            <h2 className="text-xl font-semibold text-gray-100">Conversation with {note.title.replace(/[0-9]/g, '')}</h2>
            <textarea
                value={transcription}
                onChange={(e) => handleTranscriptionUpdate(e.target.value)}
                rows={10}
                className="input-base w-full text-white rounded-md p-2"
                placeholder="Transcribed text will appear here..."
            />
            <div className="flex justify-end space-x-2 items-center">
                {isRecording && (
                    <span className="text-gray-300 text-sm mr-2">
                        {formatTime(recordingTime)}
                    </span>
                )}
                <button
                    onClick={toggleRecording}
                    disabled={isProcessing}
                    className={`flex items-center justify-center px-2 py-1 rounded-md w-auto ${
                        isRecording
                            ? 'bg-red-600 hover:bg-red-700'
                            : isProcessing 
                                ? 'bg-gray-600' 
                                : 'base-button'
                    } focus:outline-none focus:ring-1 focus:ring-white ${
                        isRecording ? 'focus:ring-red-500' : 'focus:ring-white'
                    } transition duration-150 ease-in-out ${
                        isProcessing ? 'cursor-not-allowed' : ''
                    }`}
                    title={
                        isProcessing 
                            ? "Processing..." 
                            : isRecording 
                                ? "Stop Recording" 
                                : "Start Recording"
                    }
                >
                    <span className={`material-icons text-white text-base ${isProcessing ? 'animate-spin' : ''}`}>
                        {isProcessing 
                            ? 'sync' 
                            : isRecording 
                                ? 'stop' 
                                : 'mic'
                        }
                    </span>
                    <span className="text-xs text-gray-300 whitespace-nowrap ml-1 hidden sm:inline">
                        {isProcessing 
                            ? 'Processing...' 
                            : isRecording 
                                ? 'Stop' 
                                : 'Transcribe'
                        }
                    </span>
                </button>
                <ScribePushToNote
                    transcription={transcription}
                    currentPreset={currentPreset}
                    updateNote={updateNote}
                    note={note}
                />
            </div>
        </div>
    );
};

export default Scribe;