import React from 'react';
import PresetSelector from '../common/PresetSelector';
import DocumentCopyButton from './buttons/DocumentCopyButton';
import DocumentExportButton from './buttons/DocumentExportButton';
import NoteContextButton from './buttons/NoteContextButton';

const documentPresets = [
    { full: 'Letter', short: 'Letter' },
    { full: 'Education', short: 'Edu' },
    { full: 'Communications', short: 'Comm' }
];

function DocumentEditorHeader({ document, onUpdateDocument, currentPreset, handlePresetChange, className }) {
    return (
        <div className={`flex flex-col gap-3 ${className || ''}`}>
            <div className="flex flex-wrap gap-2 items-center justify-between">
                <PresetSelector 
                    presets={documentPresets} 
                    currentPreset={currentPreset} 
                    onPresetChange={handlePresetChange} 
                />
                <div className="flex flex-wrap gap-2">
                    <DocumentCopyButton document={document} currentPreset={currentPreset} />
                    <DocumentExportButton document={document} currentPreset={currentPreset} />
                </div>
            </div>
            <NoteContextButton 
                document={document}
                onUpdateDocument={onUpdateDocument}
            />
        </div>
    );
}

export default DocumentEditorHeader;