import React from 'react';

function SidebarButtons({ 
    isPanelCollapsed, 
    handleNewDocument: onNewDocument,
    documentsExist
}) {
    const handleNewDocumentClick = () => {
        onNewDocument();
    };

    if (isPanelCollapsed) {
        return (
            <button
                onClick={handleNewDocumentClick}
                title="New Document"
                className="w-full flex items-center justify-center p-2 primary-button rounded transition duration-150 ease-in-out text-white flash-button"
            >
                <span className="material-symbols-outlined">note_add</span>
            </button>
        );
    }

    return documentsExist ? (
        <div className="flex space-x-2">
            <button
                onClick={handleNewDocumentClick}
                title="New Document"
                className="w-full flex items-center justify-center p-2 primary-button rounded transition duration-150 ease-in-out text-white flash-button"
            >
                <span className="material-symbols-outlined">note_add</span>
                New
            </button>
        </div>
    ) : (
        <button
            onClick={handleNewDocumentClick}
            title="New Document"
            className="w-full flex items-center justify-center p-2 primary-button rounded transition duration-150 ease-in-out text-white flash-button"
        >
            <span className="material-symbols-outlined">note_add</span>
            New Document
        </button>
    );
}

export default SidebarButtons; 