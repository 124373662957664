import React from 'react';
import NotesSidebar from './NoteEditor/NotesSidebar';
import DocumentsSidebar from './DocumentEditor/DocumentsSidebar';

function Sidebar({
    mode = 'notes',
    ...props
}) {
    switch (mode) {
        case 'documents':
            return <DocumentsSidebar {...props} />;
        case 'notes':
        default:
            return <NotesSidebar {...props} />;
    }
}

export default Sidebar; 