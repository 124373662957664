export const POST_PROCESS_SUBSTITUTIONS = {
    'status post': 's/p',
    'gram stain': 'Gram stain',
    'gram positive': 'Gram-positive',
    'gram-positive': 'Gram-positive',
    'gram negative': 'Gram-negative',
    'gram-negative': 'Gram-negative',

    //Titles with excess line breaks
    'History of Present Illness:\n\n': 'History of Present Illness:\n',
  };