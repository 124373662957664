import React, { useState, useCallback } from 'react';
import { exportDocument } from '../../utils/docxExportUtils';

const DocumentExportButton = ({ document: documentData, currentPreset }) => {
    const [exporting, setExporting] = useState(false);

    const handleExport = useCallback(async () => {
        setExporting(true);
        try {
            await exportDocument(documentData, currentPreset);
        } catch (err) {
            console.error('Failed to export document: ', err);
        } finally {
            setExporting(false);
        }
    }, [documentData, currentPreset]);

    return (
        <button
            onClick={handleExport}
            disabled={exporting}
            className="flex items-center justify-center px-3 py-1.5 rounded-md base-button focus:outline-none focus:ring-1 focus:ring-white transition duration-150 ease-in-out flash-button"
            title="Export to .docx"
        >
            <span className="material-symbols-outlined text-white text-base mr-1">
                {exporting ? 'hourglass_empty' : 'upload_file'}
            </span>
            <span className="text-xs text-[#e0e0e0] whitespace-nowrap">Export</span>
        </button>
    );
};

export default DocumentExportButton; 