import React, { useState, useRef } from 'react';
import BaseSidebar from '../BaseSidebar';
import DraggableList from '../DraggableList';
import SidebarButtons from './SidebarButtons';
import DocumentsSidebarHeader from './DocumentsSidebarHeader';

function DocumentsSidebar({
    documents = [],
    currentDocument,
    onNewDocument,
    onDocumentSelect,
    onDeleteDocument,
    onDeleteAll,
    reorderDocuments,
    isPanelCollapsed,
    setIsPanelCollapsed
}) {
    const [deleteConfirmation, setDeleteConfirmation] = useState(null);
    const [draggedDocument, setDraggedDocument] = useState(null);
    const [draggedOverDocument, setDraggedOverDocument] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [isSearchFocused, setIsSearchFocused] = useState(false);
    const [presetFilter, setPresetFilter] = useState('all');
    
    const documentsListRef = useRef(null);

    const handleDrop = (e, targetDocument) => {
        e.preventDefault();
        const draggedDocId = e.dataTransfer.getData('text');
        const draggedIndex = documents.findIndex(doc => doc.id.toString() === draggedDocId);
        const targetIndex = documents.findIndex(doc => doc.id === targetDocument.id);

        if (draggedIndex !== targetIndex) {
            const newDocuments = Array.from(documents);
            const [reorderedItem] = newDocuments.splice(draggedIndex, 1);
            newDocuments.splice(targetIndex, 0, reorderedItem);
            reorderDocuments(newDocuments);
        }
    };

    const handleClearSearch = () => {
        setSearchTerm('');
    };

    const handlePresetFilterChange = (filter) => {
        setPresetFilter(filter);
    };

    const getPresetCounts = () => {
        return documents.reduce((counts, doc) => {
            counts.all++;
            counts[doc.preset] = (counts[doc.preset] || 0) + 1;
            return counts;
        }, { all: 0, Letter: 0, Education: 0, Communications: 0 });
    };

    const filteredDocuments = documents.filter(doc => {
        const matchesSearch = doc.title.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesPreset = presetFilter === 'all' || doc.preset === presetFilter;
        return matchesSearch && matchesPreset;
    });

    const getPresetAbbreviation = (preset) => {
        switch (preset) {
            case 'Letter': return 'LTR';
            case 'Education': return 'EDU';
            case 'Communications': return 'COM';
            default: return 'LTR';
        }
    };

    const renderDocumentContent = (document) => (
        <div className="flex items-center min-w-0 flex-grow">
            <span className="mr-2 text-xs font-semibold bg-[#2a2a2a] text-white px-1 rounded flex-shrink-0">
                {getPresetAbbreviation(document.preset)}
            </span>
            <span 
                className="truncate overflow-hidden text-ellipsis flex-grow text-[#e0e0e0]"
                title={document.title || 'Untitled Document'}
            >
                {document.title || 'Untitled Document'}
            </span>
        </div>
    );

    return (
        <BaseSidebar
            isPanelCollapsed={isPanelCollapsed}
            setIsPanelCollapsed={setIsPanelCollapsed}
            isSearchFocused={isSearchFocused}
            title="Saved Documents"
        >
            <DocumentsSidebarHeader
                isPanelCollapsed={isPanelCollapsed}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                isSearchFocused={isSearchFocused}
                setIsSearchFocused={setIsSearchFocused}
                handleClearSearch={handleClearSearch}
                presetFilter={presetFilter}
                presetCounts={getPresetCounts()}
                handlePresetFilterChange={handlePresetFilterChange}
            />

            <div className="mb-4">
                <SidebarButtons
                    isPanelCollapsed={isPanelCollapsed}
                    handleNewDocument={onNewDocument}
                    documentsExist={documents.length > 0}
                />
            </div>

            <div ref={documentsListRef} className="flex-1 overflow-y-auto -mx-4">
                <DraggableList
                    items={filteredDocuments}
                    selectedItem={currentDocument}
                    onItemSelect={onDocumentSelect}
                    onItemDelete={onDeleteDocument}
                    draggedItem={draggedDocument}
                    setDraggedItem={setDraggedDocument}
                    draggedOverItem={draggedOverDocument}
                    setDraggedOverItem={setDraggedOverDocument}
                    onDrop={handleDrop}
                    deleteConfirmation={deleteConfirmation}
                    setDeleteConfirmation={setDeleteConfirmation}
                    isPanelCollapsed={isPanelCollapsed}
                    renderItemContent={renderDocumentContent}
                />
            </div>

            {!isPanelCollapsed && documents.length > 0 && (
                <div className="-mx-4 px-4 mt-auto pt-2">
                    <button
                        onClick={onDeleteAll}
                        className="w-full flex items-center justify-center p-2 alert-button rounded transition duration-150 ease-in-out shadow-md text-white flash-button"
                    >
                        <span className="material-icons mr-2">delete_forever</span>
                        Delete All
                    </button>
                </div>
            )}
        </BaseSidebar>
    );
}

export default DocumentsSidebar; 