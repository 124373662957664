import React, { useState, useRef } from 'react';
import apiService from '../../services/api';
import { useSettings } from '../../context/SettingsContext';
import { useVisualViewport } from '../../hooks/useVisualViewport';
import { useNotes } from '../../hooks/useNotes';

function ChatBox({ document, onUpdateDocument }) {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [chatHistory, setChatHistory] = useState([]);
    const chatContainerRef = useRef(null);
    const { settings } = useSettings();
    const bottomOffset = useVisualViewport();
    const { notes } = useNotes();

    const linkedNote = notes?.find(note => note.id === document.noteId);

    const getPresetDisplayName = (preset) => {
        switch (preset.toLowerCase()) {
            case 'letter':
                return 'Letter';
            case 'education':
                return 'Educational Document';
            case 'communications':
                return 'Communication';
            default:
                return preset;
        }
    };

    const handleSendMessage = async () => {
        if (!message.trim()) return;

        setIsLoading(true);
        const userMessage = message.trim();
        setMessage('');

        // Add user message to chat history
        setChatHistory(prev => [...prev, { role: 'user', content: userMessage }]);

        try {
            const response = await apiService.chatWithAI({
                message: userMessage,
                documentContent: document.content,
                documentTitle: document.title,
                documentPreset: document.preset,
                aiProvider: settings.aiProvider,
                noteContext: linkedNote || null
            });

            // Add AI response to chat history
            setChatHistory(prev => [...prev, { role: 'assistant', content: response.message }]);

            // If the AI suggests content or title changes, update the document
            if (response.suggestedContent || response.suggestedTitle) {
                const updates = {
                    ...document,
                    ...(response.suggestedContent && { content: response.suggestedContent }),
                    ...(response.suggestedTitle && { title: response.suggestedTitle })
                };
                onUpdateDocument(updates);
            }
        } catch (error) {
            console.error('Error in chat:', error);
            setChatHistory(prev => [...prev, {
                role: 'error',
                content: 'Sorry, there was an error processing your request.'
            }]);
        } finally {
            setIsLoading(false);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    };

    return (
        <div className={`fixed right-4 z-10 ${isOpen ? 'w-96' : 'w-auto'}`}
            style={{ 
                bottom: `max(80px, calc(10vh + ${bottomOffset}px))`,
                transition: 'all 0.1s'
            }}
        >

            {!isOpen && (
                <button
                    onClick={() => setIsOpen(true)}
                    className="flex items-center justify-center p-2 sm:p-3 rounded-lg transition-all duration-300 ease-in-out bg-[#242424] hover:base-surface border-2 surface-border focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1976d2] shadow-lg"
                    title="Open Chat"
                >
                    <span className="material-symbols-outlined text-white text-2xl">tooltip_2</span>
                </button>
            )}

            {/* Chat Panel */}
            {isOpen && (
                <div className="surface-bg rounded-lg shadow-xl border surface-border flex flex-col h-[500px] overflow-hidden">
                    {/* Chat Header */}
                    <div className="p-4 border-b surface-border flex items-center justify-between">
                        <div className="flex items-center space-x-2">
                            <span className="material-symbols-outlined text-white text-2xl">tooltip_2</span>
                            <h3 className="text-lg font-semibold text-white">
                                Edit {getPresetDisplayName(document.preset)}
                            </h3>
                        </div>
                        <button
                            onClick={() => setIsOpen(false)}
                            className="p-1 hover:bg-[#323232] rounded-lg focus:outline-none"
                            title="Close Chat"
                        >
                            <span className="material-symbols-outlined text-white">close</span>
                        </button>
                    </div>

                    {/* Chat Messages */}
                    <div 
                        ref={chatContainerRef}
                        className="flex-1 overflow-y-auto p-4 space-y-4"
                    >
                        {chatHistory.map((msg, index) => (
                            <div
                                key={index}
                                className={`flex ${msg.role === 'user' ? 'justify-end' : 'justify-start'}`}
                            >
                                <div
                                    className={`max-w-[80%] rounded-lg p-3 ${
                                        msg.role === 'user'
                                            ? 'bg-[#1976d2] text-white'
                                            : msg.role === 'error'
                                            ? 'bg-red-500 text-white'
                                            : 'base-surface text-white'
                                    }`}
                                >
                                    <p className="whitespace-pre-wrap">{msg.content}</p>
                                </div>
                            </div>
                        ))}
                        {isLoading && (
                            <div className="flex justify-start">
                                <div className="base-surface text-white rounded-lg p-3">
                                    <div className="flex items-center space-x-2">
                                        <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '0ms' }} />
                                        <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '150ms' }} />
                                        <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '300ms' }} />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Chat Input */}
                    <div className="p-4 border-t surface-border">
                        <div className="flex items-center space-x-2">
                            <textarea
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                onKeyDown={handleKeyPress}
                                className="flex-1 input-base rounded-lg py-2 px-3 resize-none"
                                rows={2}
                                disabled={isLoading}
                            />
                            <button
                                onClick={handleSendMessage}
                                disabled={isLoading || !message.trim()}
                                className="p-2 text-white rounded-md primary-button disabled:opacity-50"
                                title="Send message"
                            >
                                <span className="material-symbols-outlined">send</span>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ChatBox; 