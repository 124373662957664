import { useCallback } from 'react';

export const useTabNavigation = (section, presetSections, currentPreset, allRefs) => {
    return useCallback((e) => {
        if (e.key === 'Tab') {
            e.preventDefault();
            
            let nextRefKey;
            
            if (section === 'title') {
                // If we're in the title field, go to first section
                const firstSection = presetSections[currentPreset][0];
                nextRefKey = `${firstSection}Ref`;
            } else {
                // For regular sections
                const currentSections = presetSections[currentPreset];
                const currentIndex = currentSections.indexOf(section);
                
                // If we're at the last section, go to title, otherwise go to next section
                if (currentIndex === currentSections.length - 1) {
                    nextRefKey = 'titleRef';
                } else {
                    const nextSection = currentSections[currentIndex + 1];
                    nextRefKey = `${nextSection}Ref`;
                }
            }
            
            // Focus the next section's textarea if ref exists
            if (allRefs && allRefs[nextRefKey]?.current) {
                allRefs[nextRefKey].current.focus();
                // Also scroll the element into view if needed
                allRefs[nextRefKey].current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        }
    }, [section, presetSections, currentPreset, allRefs]);
}; 