import { useState, useEffect, useCallback } from 'react';
import apiService from '../services/api';
import { debounce } from 'lodash';

// Cache for documents data
let documentsCache = null;
let lastFetchTimestamp = null;
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

const useDocuments = () => {
    const [documents, setDocuments] = useState(documentsCache || []);
    const [currentDocument, setCurrentDocument] = useState(null);
    const [isLoading, setIsLoading] = useState(!documentsCache);
    // eslint-disable-next-line no-unused-vars
    const [lastViewedDocumentId, setLastViewedDocumentId] = useState(null);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [actionSuccess, setActionSuccess] = useState({ save: null });

    const invalidateCache = useCallback(() => {
        documentsCache = null;
        lastFetchTimestamp = null;
    }, []);

    const shouldRefetch = useCallback(() => {
        return !documentsCache || !lastFetchTimestamp || (Date.now() - lastFetchTimestamp > CACHE_DURATION);
    }, []);

    // Helper function to select the appropriate document
    const selectAppropriateDocument = useCallback((docs, lastViewedId) => {
        if (!docs || docs.length === 0) {
            setCurrentDocument(null);
            return;
        }

        // Convert IDs to strings for consistent comparison
        const targetId = lastViewedId?.toString();
        const documentToSelect = targetId 
            ? docs.find(doc => doc.id?.toString() === targetId)
            : null;

        // If we found the last viewed document, use it; otherwise use the first document
        setCurrentDocument(documentToSelect || docs[0]);
    }, []);

    const fetchDocuments = useCallback(async (force = false) => {
        if (!force && !shouldRefetch()) {
            // If using cached data, ensure we have the correct document selected
            selectAppropriateDocument(documentsCache, lastViewedDocumentId);
            return;
        }

        try {
            setIsLoading(true);
            const fetchedDocuments = await apiService.fetchDocuments();
            documentsCache = fetchedDocuments;
            lastFetchTimestamp = Date.now();
            setDocuments(fetchedDocuments);


            // Fetch last viewed document ID
            const { lastViewedDocumentId: fetchedLastViewedId } = await apiService.fetchLastViewedDocument();
            setLastViewedDocumentId(fetchedLastViewedId);

            // Select appropriate document
            selectAppropriateDocument(fetchedDocuments, fetchedLastViewedId);
        } catch (error) {
            console.error('Error fetching documents:', error);
            invalidateCache();
        } finally {
            setIsLoading(false);
        }
    }, [shouldRefetch, invalidateCache, selectAppropriateDocument, lastViewedDocumentId]);


    useEffect(() => {
        fetchDocuments();
    }, [fetchDocuments]);

    const selectDocument = useCallback(async (document) => {
        if (!document) return;
        
        try {
            setCurrentDocument(document);
            const documentId = document.id?.toString();
            await apiService.updateLastViewedDocument(document.id);
            setLastViewedDocumentId(documentId);
        } catch (error) {
            console.error('Error updating last viewed document:', error);
        }
    }, []);

    const createDocument = useCallback(async () => {
        const newDocument = {
            title: 'Untitled Document',
            content: '',
            preset: 'Letter',
        };

        // For the first document, ensure the cache is initialized
        if (!documentsCache) {
            documentsCache = [];
            lastFetchTimestamp = Date.now();
        }

        // Create optimistic document with temporary ID
        const tempDocument = {
            ...newDocument,
            id: Date.now(), // Use timestamp as temporary ID for consistency
            isOptimistic: true
        };
        
        // Optimistically update local state
        setDocuments(prevDocuments => [...prevDocuments, tempDocument]);
        setCurrentDocument(tempDocument);
        
        try {
            const createdDocument = await apiService.createDocument(newDocument);
            
            // Update both state and cache with the server-generated ID
            setDocuments(prevDocuments => {
                const updatedDocuments = prevDocuments.map(doc =>
                    doc.id === tempDocument.id ? createdDocument : doc
                );
                documentsCache = updatedDocuments; // Update cache with the new state
                return updatedDocuments;
            });
            setCurrentDocument(createdDocument);
            
            // Update last viewed document
            await apiService.updateLastViewedDocument(createdDocument.id);
            setLastViewedDocumentId(createdDocument.id.toString());

            return createdDocument;
        } catch (error) {
            console.error('Error creating document:', error);
            // Revert the optimistic update
            setDocuments(prevDocuments => 
                prevDocuments.filter(doc => doc.id !== tempDocument.id)
            );
            setCurrentDocument(null);
            throw error;
        }
    }, []);

    const deleteDocument = async (documentId) => {
        try {
            await apiService.deleteDocument(documentId);
            
            // Ensure consistent ID format for comparison
            const currentId = currentDocument?.id?.toString();
            const targetId = documentId?.toString();
            const wasCurrentDocument = currentId === targetId;
            
            const updatedDocuments = await new Promise(resolve => {
                setDocuments(prevDocuments => {
                    // Find index before filtering
                    const deletedIndex = prevDocuments.findIndex(doc => doc.id?.toString() === targetId);
                    const updated = prevDocuments.filter(doc => doc.id?.toString() !== targetId);
                    documentsCache = updated;
                    lastFetchTimestamp = Date.now();
                    
                    console.log('Delete operation:', {
                        deletedId: targetId,
                        currentId,
                        deletedIndex,
                        lastViewedId: lastViewedDocumentId,
                        remainingIds: updated.map(d => d.id?.toString()),
                        wasCurrentDocument
                    });
                    
                    resolve({ updated, deletedIndex });
                    return updated;
                });
            });
            
            if (wasCurrentDocument && updatedDocuments.updated.length > 0) {
                // Try to select a document close to the one we deleted
                const nextDoc = updatedDocuments.updated[updatedDocuments.deletedIndex] || // Same position
                              updatedDocuments.updated[updatedDocuments.deletedIndex - 1] || // Previous
                              updatedDocuments.updated[0]; // First as fallback
                
                console.log('Selection after delete:', {
                    deletedIndex: updatedDocuments.deletedIndex,
                    selectedId: nextDoc.id?.toString(),
                    availableIds: updatedDocuments.updated.map(d => d.id?.toString())
                });
                
                setCurrentDocument(nextDoc);
                setLastViewedDocumentId(nextDoc.id.toString());
            } else if (updatedDocuments.updated.length === 0) {
                setCurrentDocument(null);
                setLastViewedDocumentId(null);
            }
        } catch (error) {
            console.error('Error deleting document:', error);
            invalidateCache();
            await fetchDocuments(true);
            throw error;
        }
    };

    const deleteAllDocuments = async () => {
        try {
            await apiService.deleteAllDocuments();
            setDocuments([]);
            setCurrentDocument(null);
            setLastViewedDocumentId(null);
            invalidateCache();
        } catch (error) {
            console.error('Error deleting all documents:', error);
            throw error;
        }
    };

    const handleSave = useCallback(async () => {
        if (!currentDocument || !hasUnsavedChanges) return;

        setActionSuccess(prev => ({ ...prev, save: 'saving' }));
        try {
            const document = await apiService.updateDocument(currentDocument.id, currentDocument);
            setDocuments(prevDocs => 
                prevDocs.map(doc => 
                    doc.id === currentDocument.id ? document : doc
                )
            );
            documentsCache = documents.map(doc => 
                doc.id === currentDocument.id ? document : doc
            );
            setCurrentDocument(document);
            setHasUnsavedChanges(false);
            setActionSuccess(prev => ({ ...prev, save: true }));
            setTimeout(() => {
                setActionSuccess(prev => ({ ...prev, save: null }));
            }, 2000);
            return document;
        } catch (error) {
            console.error('Error updating document:', error);
            setActionSuccess(prev => ({ ...prev, save: 'error' }));
            setTimeout(() => {
                setActionSuccess(prev => ({ ...prev, save: null }));
            }, 2000);
            throw error;
        }
    }, [currentDocument, hasUnsavedChanges, documents]);

    const debouncedSave = useCallback(() => {
        const saveIfNeeded = debounce(() => {
            if (hasUnsavedChanges) {
                handleSave();
            }
        }, 10000);

        saveIfNeeded();
        return () => saveIfNeeded.cancel();
    }, [hasUnsavedChanges, handleSave]);

    useEffect(() => {
        let cleanup;
        if (hasUnsavedChanges) {
            cleanup = debouncedSave();
        }
        return () => {
            if (cleanup) cleanup();
        };
    }, [hasUnsavedChanges, debouncedSave]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.key === 's') {
                event.preventDefault();
                if (hasUnsavedChanges) {
                    handleSave();
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [hasUnsavedChanges, handleSave]);

    const updateDocument = useCallback((updatedDocument) => {
        setCurrentDocument(prevDoc => ({
            ...prevDoc,
            ...updatedDocument
        }));
        setHasUnsavedChanges(true);
    }, []);

    const reorderDocuments = async (newDocuments) => {
        try {
            await Promise.all(newDocuments.map((doc, index) => 
                apiService.reorderDocument(doc.id, index)
            ));
            setDocuments(newDocuments);
        } catch (error) {
            console.error('Error reordering documents:', error);
            throw error;
        }
    };

    return {
        documents,
        currentDocument,
        isLoading,
        createDocument,
        selectDocument,
        deleteDocument,
        deleteAllDocuments,
        updateDocument,
        reorderDocuments,
        hasUnsavedChanges,
        actionSuccess,
        handleSave,
        invalidateCache,
        fetchDocuments
    };
};

export default useDocuments; 