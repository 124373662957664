import { isTomorrow } from 'date-fns';

export const createNoteSignature = (notePreset, settings) => {
    let signature = '';

    if (notePreset === "ID Consult") {
        signature = "\nDiscussed recommendations with Primary Team.\n\n";
    }

    //Timed naming of person you are signing out to.
    if (settings.signoutDate && settings.signoutPerson) {
        if (isTomorrow(settings.signoutDate)) {
            signature += `Dr. ${settings.signoutPerson} will assume care of service tomorrow.\n\n`;
        }
    }

    signature += `Thank you for involving us in the care of your patient. Please reach out to the Infectious Diseases consultation team for any questions or concerns.

Terry Marryshow, MD
Infectious Disease Attending
Reachable via TigerConnect`;

    if (notePreset === "ID Consult") {
        signature += "\n\nBilling: 99223";
    } else if (notePreset === "ID Follow Up") {
        signature += "\n\nBilling: 99233";
    }

    return signature;
};