import React, { useState, useEffect, useCallback, useRef } from 'react';
import { renderHighlightedLine } from '../../utils/noteHighlightUtils';

const Todo = React.memo(({ note, updateNote }) => {
    const [todos, setTodos] = useState([]);
    const [newTodo, setNewTodo] = useState('');
    const [editingId, setEditingId] = useState(null);
    const [draggedTodo, setDraggedTodo] = useState(null);
    const [draggedOverTodo, setDraggedOverTodo] = useState(null);
    const [completedTodos, setCompletedTodos] = useState({});
    const [isRecommendationsOpen, setIsRecommendationsOpen] = useState(true);
    const [recommendations, setRecommendations] = useState('');
    const textareaRef = useRef(null);
    const [editingText, setEditingText] = useState('');

    useEffect(() => {
        if (note && note.todos) {
            setTodos(note.todos);
        }
        if (note && note.recommendations) {
            setRecommendations(note.recommendations);
        }
    }, [note]);

    const addTodo = useCallback((e) => {
        e.preventDefault();
        if (!newTodo.trim()) return;
        
        const newTodoItem = {
            id: Date.now(),
            text: newTodo,
            completed: false
        };
        
        const updatedTodos = [...todos, newTodoItem];
        setTodos(updatedTodos);
        updateNote('todos', updatedTodos);
        setNewTodo('');
    }, [newTodo, todos, updateNote]);

    const toggleTodo = useCallback((id) => {
        setCompletedTodos(prev => ({ ...prev, [id]: !prev[id] }));

        // Delete the todo after a short delay
        setTimeout(() => {
            const updatedTodos = todos.filter(todo => todo.id !== id);
            setTodos(updatedTodos);
            updateNote('todos', updatedTodos);
            setCompletedTodos(prev => {
                const newState = { ...prev };
                delete newState[id];
                return newState;
            });
        }, 300);
    }, [todos, updateNote]);

    const startEditing = useCallback((id) => {
        const todo = todos.find(t => t.id === id);
        setEditingText(todo.text);
        setEditingId(id);
    }, [todos]);

    const finishEditing = useCallback((id, newText) => {
        const todo = todos.find(t => t.id === id);
        if (todo && todo.text !== newText) {
            const updatedTodos = todos.map(todo =>
                todo.id === id ? { ...todo, text: newText } : todo
            );
            setTodos(updatedTodos);
            updateNote('todos', updatedTodos);
        }
        setEditingId(null);
    }, [todos, updateNote]);

    const handleEditKeyDown = useCallback((e, id, newText) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            finishEditing(id, newText);
        }
    }, [finishEditing]);

    const handleDragStart = useCallback((e, todo) => {
        if (editingId !== null) {
            e.preventDefault();
            return;
        }
        setDraggedTodo(todo);
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text/plain', todo.id);
        e.target.style.opacity = '0.5';
    }, [editingId]);

    const handleDragOver = useCallback((e, todo) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';
        setDraggedOverTodo(todo);
    }, []);

    const handleDrop = useCallback((e, targetTodo) => {
        e.preventDefault();
        if (!draggedTodo || draggedTodo.id === targetTodo.id) return;

        const newTodos = [...todos];
        const draggedIndex = newTodos.findIndex(todo => todo.id === draggedTodo.id);
        const targetIndex = newTodos.findIndex(todo => todo.id === targetTodo.id);

        newTodos.splice(draggedIndex, 1);
        newTodos.splice(targetIndex, 0, draggedTodo);

        const updatedTodos = newTodos.map((todo, index) => ({ ...todo, order: index }));
        setTodos(updatedTodos);
        updateNote('todos', updatedTodos);
    }, [draggedTodo, todos, updateNote]);

    const handleDragEnd = useCallback((e) => {
        e.target.style.opacity = '1';
        setDraggedTodo(null);
        setDraggedOverTodo(null);
    }, []);

    const getStyles = useCallback((todo) => {
        if (draggedTodo && draggedTodo.id === todo.id) {
            return "opacity-50";
        }
        if (draggedOverTodo && draggedOverTodo.id === todo.id) {
            const draggedIndex = todos.findIndex(t => t.id === draggedTodo.id);
            const targetIndex = todos.findIndex(t => t.id === todo.id);
            if (draggedIndex < targetIndex) {
                return "border-b-2 border-blue-500";
            } else {
                return "border-t-2 border-blue-500";
            }
        }
        return "";
    }, [draggedTodo, draggedOverTodo, todos]);

    const adjustTextareaHeight = useCallback(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
        }
    }, []);

    const handleRecommendationsChange = useCallback((e) => {
        const newRecommendations = e.target.value;
        setRecommendations(newRecommendations);
        updateNote('recommendations', newRecommendations);
        adjustTextareaHeight();
    }, [updateNote, adjustTextareaHeight]);

    // Adjust textarea height on window resize
    useEffect(() => {
        const handleResize = () => {
            adjustTextareaHeight();
        };

        // Initial adjustment
        adjustTextareaHeight();
        
        // Add resize listener
        window.addEventListener('resize', handleResize);
        
        // Cleanup
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [adjustTextareaHeight, recommendations, isRecommendationsOpen]);

    const HighlightedDisplay = useCallback(({ text }) => (
        <div style={{
            color: 'white',
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            wordBreak: 'break-word',
            overflowWrap: 'break-word',
            width: '100%',
            overflow: 'hidden',
            lineHeight: '1.5',
        }}>
            {text.split('\n').map((line, index) => (
                renderHighlightedLine(line, index, text, null, false)
            ))}
        </div>
    ), []);

    return (
        <div className="space-y-6 h-full flex flex-col">
            <h2 className="text-xl font-semibold text-white mb-4">
                Todo List for {note.title.replace(/[0-9]/g, '')}
            </h2>
            
            {note.recommendations !== undefined && (
                <div className="mb-4 bg-[#242424] border surface-border rounded-lg overflow-hidden">
                    <button
                        className="w-full p-3 text-left text-white font-semibold bg-[#2a2a2a] hover:bg-gray-500 transition-colors duration-200 flex justify-between items-center"
                        onClick={() => setIsRecommendationsOpen(!isRecommendationsOpen)}
                    >
                        Plan
                        <svg
                            className={`w-5 h-5 transform transition-transform duration-200 ${isRecommendationsOpen ? 'rotate-180' : ''}`}
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                        </svg>
                    </button>
                    {isRecommendationsOpen && (
                        <div className="relative w-full">
                            <textarea
                                ref={textareaRef}
                                className="w-full p-3 bg-[#242424] border-none resize-none overflow-hidden focus:outline-none caret-white absolute inset-0"
                                value={recommendations}
                                onChange={handleRecommendationsChange}
                                rows={1}
                                style={{
                                    color: 'transparent',
                                    caretColor: 'white',
                                    minHeight: '100px',
                                    whiteSpace: 'pre-wrap',
                                    wordBreak: 'break-word',
                                    overflowWrap: 'break-word',
                                    lineHeight: '1.5',
                                }}
                            />
                            <div 
                                className="w-full p-3"
                                style={{
                                    pointerEvents: 'none',
                                    minHeight: textareaRef.current?.scrollHeight + 'px',
                                    whiteSpace: 'pre-wrap',
                                    wordBreak: 'break-word',
                                    overflowWrap: 'break-word',
                                    lineHeight: '1.5',
                                }}
                            >
                                <HighlightedDisplay text={recommendations} />
                            </div>
                        </div>
                    )}
                </div>
            )}

            <div className="flex-grow overflow-y-auto pr-2">
                <form onSubmit={addTodo} className="mb-4 flex">
                    <input
                        type="text"
                        value={newTodo}
                        onChange={(e) => setNewTodo(e.target.value)}
                        placeholder="Add a new task"
                        className="input-base flex-grow p-2 rounded-l"
                    />
                    <button
                        type="submit"
                        className="primary-button text-white px-4 py-2 rounded-r transition duration-200 flash-button"
                    >
                        Add
                    </button>
                </form>
                <ul className="space-y-2">
                    {todos.map((todo) => (
                        <li
                            key={todo.id}
                            draggable={editingId !== todo.id}
                            onDragStart={(e) => handleDragStart(e, todo)}
                            onDragOver={(e) => handleDragOver(e, todo)}
                            onDrop={(e) => handleDrop(e, todo)}
                            onDragEnd={handleDragEnd}
                            className={`flex items-center bg-[#2a2a2a] p-3 rounded ${editingId !== todo.id ? 'cursor-move' : ''} ${getStyles(todo)}`}
                        >
                            <div className="flex items-center h-5">
                                <input
                                    id={`todo-${todo.id}`}
                                    type="checkbox"
                                    checked={completedTodos[todo.id] || false}
                                    onChange={() => toggleTodo(todo.id)}
                                    className="hidden"
                                />
                                <label
                                    htmlFor={`todo-${todo.id}`}
                                    className="flex items-center justify-center w-5 h-5 border-2 border-gray-400 rounded-md cursor-pointer hover:border-blue-500 transition-colors duration-200 flash-button"
                                >
                                    <svg
                                        className="w-3 h-3 text-blue-500 pointer-events-none checkmark-icon"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M5 13l4 4L19 7"
                                        ></path>
                                    </svg>
                                </label>
                            </div>
                            {editingId === todo.id ? (
                                <input
                                    type="text"
                                    value={editingText}
                                    onChange={(e) => setEditingText(e.target.value)}
                                    onBlur={() => finishEditing(todo.id, editingText)}
                                    onKeyDown={(e) => handleEditKeyDown(e, todo.id, editingText)}
                                    className="input-base flex-grow text-white p-1 rounded ml-3"
                                    autoFocus
                                />
                            ) : (
                                <span
                                    className="flex-grow text-white ml-3 cursor-pointer"
                                    onClick={() => startEditing(todo.id)}
                                >
                                    {todo.text}
                                </span>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
});

export default Todo;