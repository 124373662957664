import { useCallback } from 'react';
import { POST_PROCESS_SUBSTITUTIONS } from '../components/data/postProcessSubstitutions';

export const useTextPostprocessing = () => {
  const postProcessText = useCallback((text) => {
    if (!text) return text;
    
    let processedText = text;
    
    Object.entries(POST_PROCESS_SUBSTITUTIONS).forEach(([pattern, replacement]) => {
      // Handle patterns with newlines differently from word-based substitutions
      if (pattern.includes('\n')) {
        processedText = processedText.replace(new RegExp(pattern, 'g'), replacement);
      } else {
        const regex = new RegExp(`(?<![\\w-])${pattern}(?![\\w-])`, 'gi');
        processedText = processedText.replace(regex, replacement);
      }
    });
    
    // Only collapse multiple spaces within lines, preserve line breaks
    return processedText
      .split('\n')
      .map(line => line.replace(/\s+/g, ' ').trim())
      .join('\n')
      .trim();
  }, []);

  return { postProcessText };
};