import React, { useState, useEffect } from 'react';
import DocumentEditorContent from './DocumentEditorContent';
import DocumentSaveButton from './buttons/DocumentSaveButton';

function DocumentEditor({ document, onUpdateDocument, handleSave, actionSuccess, hasUnsavedChanges, collapseHeader }) {
    const [currentPreset, setCurrentPreset] = useState(document.preset || 'Letter');

    const handlePresetChange = (preset) => {
        setCurrentPreset(preset);
        const updatedDocument = { ...document, preset };
        onUpdateDocument(updatedDocument);
    };

    // Ensures that loading a new document updates from its database entry
    useEffect(() => {
        setCurrentPreset(document.preset || 'Letter');
    }, [document]);

    return (
        <div className="min-h-screen app-bg pt-24 sm:pt-28">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-20">
                <DocumentEditorContent
                    document={document}
                    onUpdateDocument={onUpdateDocument}
                    currentPreset={currentPreset}
                    handlePresetChange={handlePresetChange}
                    collapseHeader={collapseHeader}
                />
            </div>
            <DocumentSaveButton
                handleSave={handleSave}
                actionSuccess={actionSuccess}
                hasUnsavedChanges={hasUnsavedChanges}
            />
        </div>
    );
}

export default DocumentEditor; 