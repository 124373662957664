import React from 'react';
import SaveButton from '../../common/SaveButton';

function NoteSaveButton({ handleSave, actionSuccess, hasUnsavedChanges }) {
    return (
        <SaveButton
            handleSave={handleSave}
            actionSuccess={actionSuccess}
            hasUnsavedChanges={hasUnsavedChanges}
        />
    );
}

export default NoteSaveButton;