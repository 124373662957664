import React, { useState, useEffect, useRef, useCallback } from 'react';
import dotPhrases from '../data/dotPhrases';
import HighlightedTextArea from './HighlightedTextArea';
import { useTabNavigation } from '../../hooks/useTabNavigation';

const DotPhraseTextArea = React.forwardRef(({ 
    value, 
    section, 
    onChange, 
    id, 
    isSticky, 
    isRecording, 
    onFocus, 
    onBlur,
    presetSections,
    currentPreset,
    allRefs
}, ref) => {
    const [isVisible, setIsVisible] = useState(false);
    const [matchingPhrases, setMatchingPhrases] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [cursorPosition, setCursorPosition] = useState(0);
    const dropdownRef = useRef(null);
    const containerRef = useRef(null);
    const handleTabNavigation = useTabNavigation(section, presetSections, currentPreset, allRefs);

    const updateMatchingPhrases = useCallback(() => {
        const words = value.slice(0, cursorPosition).split(/\s+/);
        const lastWord = words[words.length - 1];

        if (/^\.[a-zA-Z]+/.test(lastWord)) {
            const matches = Object.keys(dotPhrases).filter(key => key.startsWith(lastWord));
            setMatchingPhrases(matches);
            setIsVisible(matches.length > 0);
            setSelectedIndex(0);
        } else {
            setIsVisible(false);
        }
    }, [value, cursorPosition]);

    useEffect(() => {
        updateMatchingPhrases();
    }, [value, cursorPosition, updateMatchingPhrases]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleSelect = useCallback((phrase) => {
        const replacement = dotPhrases[phrase];
        const beforeCursor = value.slice(0, cursorPosition);
        const afterCursor = value.slice(cursorPosition);

        const lastWordMatch = beforeCursor.match(/\.[a-zA-Z]\w*$/);
        const lastWord = lastWordMatch ? lastWordMatch[0] : '';
        const newContent = beforeCursor.slice(0, -lastWord.length) + replacement + afterCursor;

        onChange({ target: { value: newContent } });
        setIsVisible(false);
    }, [value, cursorPosition, onChange]);

    const handleKeyDown = (e) => {
        // Handle Tab key for section navigation
        if (e.key === 'Tab') {
            handleTabNavigation(e);
            return;
        }

        // Handle F2 key for asterisk navigation
        if (e.key === 'F2') {
            e.preventDefault();
            const curPos = e.target.selectionStart;
            const asteriskPositions = [];
            let searchPos = 0;
            
            while (true) {
                const found = value.indexOf('***', searchPos);
                if (found === -1) break;
                asteriskPositions.push(found);
                searchPos = found + 1;
            }
            
            if (asteriskPositions.length > 0) {
                const nextIndex = asteriskPositions.findIndex(pos => pos > curPos);
                const newPos = nextIndex === -1 ? asteriskPositions[0] : asteriskPositions[nextIndex];
                
                e.target.setSelectionRange(newPos, newPos + 3);
            }
            return;
        }

        if (isVisible) {
            switch (e.key) {
                case 'ArrowDown':
                    e.preventDefault();
                    setSelectedIndex((prevIndex) => (prevIndex + 1) % matchingPhrases.length);
                    break;
                case 'ArrowUp':
                    e.preventDefault();
                    setSelectedIndex((prevIndex) => (prevIndex - 1 + matchingPhrases.length) % matchingPhrases.length);
                    break;
                case 'Enter':
                    e.preventDefault();
                    if (matchingPhrases[selectedIndex]) {
                        handleSelect(matchingPhrases[selectedIndex]);
                    }
                    break;
                case 'Escape':
                    setIsVisible(false);
                    break;
                default:
                    break;
            }
        }
    };

    const getDropdownPosition = useCallback(() => {
        if (!ref?.current || !containerRef?.current) {
            return {};
        }

        const textareaRect = ref.current.getBoundingClientRect();
        const containerRect = containerRef.current.getBoundingClientRect();

        const dropdownWidth = Math.max(200, textareaRect.width * 0.5);
        const leftPosition = Math.min(
            textareaRect.left - containerRect.left,
            containerRect.width - dropdownWidth
        );

        return {
            top: textareaRect.bottom - containerRect.top,
            left: leftPosition,
            width: dropdownWidth,
        };
    }, [ref]);

    const [dropdownPosition, setDropdownPosition] = useState({});

    useEffect(() => {
        const updateDropdownPosition = () => setDropdownPosition(getDropdownPosition());
        updateDropdownPosition();
        window.addEventListener('resize', updateDropdownPosition);
        return () => window.removeEventListener('resize', updateDropdownPosition);
    }, [getDropdownPosition]);

    return (
        <div ref={containerRef} className="relative w-full">
            <HighlightedTextArea
                ref={ref}
                value={value}
                onChange={e => {
                    onChange(e);
                    setCursorPosition(e.target.selectionStart);
                }}
                onKeyDown={handleKeyDown}
                onSelect={e => setCursorPosition(e.target.selectionStart)}
                onFocus={onFocus}
                onBlur={onBlur}
                id={id}
                isSticky={isSticky}
                isRecording={isRecording}
                section={section}
            />
            {isVisible && (
                <div
                    ref={dropdownRef}
                    className="absolute z-10 bg-[#242424] surface-border rounded-md shadow-lg overflow-hidden"
                    style={{
                        top: `${dropdownPosition.top}px`,
                        left: `${dropdownPosition.left}px`,
                        width: `${dropdownPosition.width}px`,
                        maxHeight: '200px',
                        overflowY: 'auto'
                    }}
                >
                    {matchingPhrases.map((phrase, index) => (
                        <div
                            key={phrase}
                            className={`px-4 py-2 cursor-pointer transition-colors duration-150 ease-in-out ${
                                index === selectedIndex
                                    ? 'bg-[#1976d2] text-white'
                                    : 'text-[#e0e0e0] hover:base-surface'
                            }`}
                            onClick={() => handleSelect(phrase)}
                        >
                            <span className="font-medium">{phrase}</span>
                            <span className="block text-xs text-[#ababab] truncate">
                                {dotPhrases[phrase].substring(0, 40)}...
                            </span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
});

export default DotPhraseTextArea; 