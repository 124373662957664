import React, { useState } from 'react';
import DocumentsSidebar from '../Sidebar/DocumentEditor/DocumentsSidebar';
import DocumentEditor from '../DocumentEditor';
import DeleteAllConfirmation from '../common/DeleteAllConfirmation';
import useDocuments from '../../hooks/useDocuments';

const DocumentsMode = ({ collapseHeader }) => {
    const [isPanelCollapsed, setIsPanelCollapsed] = useState(true);
    const [showDeleteAllConfirmation, setShowDeleteAllConfirmation] = useState(false);
    
    const {
        documents,
        currentDocument,
        isLoading,
        createDocument,
        selectDocument,
        deleteDocument,
        deleteAllDocuments,
        updateDocument,
        reorderDocuments,
        hasUnsavedChanges,
        actionSuccess,
        handleSave
    } = useDocuments();

    if (isLoading) {
        return (
            <div className="flex flex-col items-center justify-center h-screen app-bg text-white font-sans">
                <p className="mb-4 text-xl sm:text-2xl font-bold">
                    Loading documents
                    <span className="animate-ellipsis">
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </p>
                <p className="text-sm text-[#ababab]">This may take a few moments</p>
            </div>
        );
    }

    return (
        <div className="documents-mode">
            <DocumentsSidebar
                documents={documents}
                currentDocument={currentDocument}
                onDocumentSelect={selectDocument}
                onNewDocument={createDocument}
                onDeleteDocument={deleteDocument}
                onDeleteAll={() => setShowDeleteAllConfirmation(true)}
                reorderDocuments={reorderDocuments}
                isPanelCollapsed={isPanelCollapsed}
                setIsPanelCollapsed={setIsPanelCollapsed}
            />
            <div className="transition-all duration-300 ml-14 sm:ml-16">
                <main className="p-4">
                    {currentDocument ? (
                        <DocumentEditor
                            document={currentDocument}
                            onUpdateDocument={updateDocument}
                            handleSave={handleSave}
                            actionSuccess={actionSuccess}
                            hasUnsavedChanges={hasUnsavedChanges}
                            collapseHeader={collapseHeader}
                        />
                    ) : (
                        <div className="flex items-center justify-center h-[calc(100vh-8rem)] pt-16">
                            <p className="text-[#ababab]">Create a new document from the sidebar.</p>
                        </div>
                    )}
                </main>
            </div>
            {showDeleteAllConfirmation && (
                <DeleteAllConfirmation
                    itemType="Documents"
                    onConfirm={() => {
                        deleteAllDocuments();
                        setShowDeleteAllConfirmation(false);
                    }}
                    onCancel={() => setShowDeleteAllConfirmation(false)}
                />
            )}
        </div>
    );
};

export default DocumentsMode; 