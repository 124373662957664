import React, { useState, useEffect, useCallback } from 'react';
import './index.css';
import Header from './components/Header';
import Snackbar from './components/Snackbar';
import Login from './components/Login';
import { NotesMode, DocumentsMode } from './components/Modes';
import { useInactivityTimer } from './hooks/useInactivityTimer';
import { useSettings } from './context/SettingsContext';
import apiService from './services/api';

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [authChecked, setAuthChecked] = useState(false);
    const [collapseHeader, setCollapseHeader] = useState(false);
    const [currentMode, setCurrentMode] = useState('notes'); // 'notes' or 'documents'
    const { loadSettings } = useSettings();

    const handleLogin = useCallback((token, collapseHeaderValue) => {
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('collapseHeader', collapseHeaderValue);
        setIsAuthenticated(true);
        apiService.setAuthToken(token);
        setCollapseHeader(collapseHeaderValue);
        loadSettings();
    }, [loadSettings]);

    const handleLogout = useCallback(async () => {
        try {
            await apiService.logout();
            setIsAuthenticated(false);
            apiService.setAuthToken(null);
            setCollapseHeader(true);
            sessionStorage.removeItem('token');
        } catch (error) {
            console.error('Error during logout:', error);
        }
    }, []);

    const handleInactivity = useCallback(() => {
        console.log('User inactive, logging out');
        handleLogout();
    }, [handleLogout]);

    useInactivityTimer(handleInactivity);

    useEffect(() => {
        const checkAuth = () => {
            const token = sessionStorage.getItem('token');
            const savedCollapseHeader = sessionStorage.getItem('collapseHeader');
            if (token) {
                setIsAuthenticated(true);
                apiService.setAuthToken(token);
                setCollapseHeader(savedCollapseHeader === 'true');
            } else {
                setIsAuthenticated(false);
                apiService.setAuthToken(null);
            }
            setAuthChecked(true);
        };
        checkAuth();
    }, []);

    if (!authChecked) {
        return (
            <div className="flex flex-col items-center justify-center h-screen surface-bg text-white font-sans">
                <p className="mb-4">Checking authentication...</p>
            </div>
        );
    }

    if (!isAuthenticated) {
        return <Login onLogin={handleLogin} />;
    }

    return (
        <div className="app-bg text-gray-100 font-sans min-h-screen">
            <Header 
                onLogout={handleLogout} 
                collapseHeader={collapseHeader}
                currentMode={currentMode}
                onModeChange={setCurrentMode}
            />
            <div className={`pt-${collapseHeader ? '12' : '20'} sm:pt-${collapseHeader ? '16' : '24'}`}>
                {currentMode === 'notes' ? (
                    <NotesMode 
                        collapseHeader={collapseHeader}
                        authToken={apiService.authToken}
                    />
                ) : (
                    <DocumentsMode 
                        collapseHeader={collapseHeader}
                    />
                )}
            </div>
            <Snackbar />
        </div>
    );
}

export default App;