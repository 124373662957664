import React, { useCallback, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { renderHighlightedLine } from '../utils/noteHighlightUtils';

const BUTTON_SPACE = 'calc(16px + 0.25rem + 0.75rem)';

const sharedStyles = {
    fontFamily: 'inherit',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    tabSize: 4,
    letterSpacing: 'normal',
    wordSpacing: 'normal',
    padding: '0.5rem 0.75rem',
    width: '100%',
    boxSizing: 'border-box',
};

const HighlightedTextArea = React.forwardRef(({
    value,
    onChange,
    onKeyDown,
    onSelect,
    onFocus,
    onBlur,
    id,
    isSticky,
    isRecording,
    section
}, ref) => {
    const textareaRef = useRef(null);

    const autoResize = useCallback(() => {
        if (textareaRef.current) {
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            
            textareaRef.current.style.height = 'auto';
            if (isSticky) {
                textareaRef.current.style.height = `${Math.min(textareaRef.current.scrollHeight, 200)}px`;
            } else {
                textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
            }
            
            window.scrollTo(0, scrollTop);
        }
    }, [isSticky]);

    const debouncedAutoResize = useCallback(() => {
        const debouncedFn = debounce(() => autoResize(), 16);
        debouncedFn();
        return debouncedFn.cancel;
    }, [autoResize]);

    const handleChange = useCallback((e) => {
        onChange(e);
        debouncedAutoResize();
    }, [onChange, debouncedAutoResize]);

    useEffect(() => {
        autoResize();
        const handleResize = debounce(() => autoResize(), 100);
        window.addEventListener('resize', handleResize);
        
        return () => {
            window.removeEventListener('resize', handleResize);
            handleResize.cancel();
        };
    }, [value, autoResize]);

    return (
        <div className="relative w-full group">
            <div className="relative">
                <textarea
                    ref={(element) => {
                        textareaRef.current = element;
                        if (typeof ref === 'function') {
                            ref(element);
                        } else if (ref) {
                            ref.current = element;
                        }
                    }}
                    id={id}
                    value={value}
                    onChange={handleChange}
                    onKeyDown={onKeyDown}
                    onSelect={onSelect}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    className={`input-base block w-full rounded-b-md rounded-tr transition duration-150 ease-in-out px-3 py-2 whitespace-pre-wrap ${
                        isRecording ? 'shadow-[0_0_15px_rgba(239,68,68,0.7)]' : 'shadow-none'
                    } ${
                        isSticky ? 'max-h-[200px] overflow-y-auto' : ''
                    }`}
                    rows="3"
                    style={{ 
                        ...sharedStyles,
                        resize: 'none', 
                        overflow: isSticky ? 'auto' : 'hidden',
                        caretColor: 'white',
                        color: isSticky ? 'white' : 'transparent',
                        background: '#242424',
                        paddingRight: BUTTON_SPACE,
                    }}
                    onInput={autoResize}
                />
                {!isSticky && (
                    <div 
                        className="absolute top-0 left-0 right-0 bottom-0 pointer-events-none"
                        style={{
                            ...sharedStyles,
                            color: 'white',
                            overflow: 'hidden',
                            border: '1px solid transparent',
                            paddingRight: BUTTON_SPACE,
                        }}
                    >
                        {value.split('\n').map((line, index) => (
                            renderHighlightedLine(line, index, value, section)
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
});

HighlightedTextArea.displayName = 'HighlightedTextArea';

export default HighlightedTextArea; 