import React, { useState } from 'react';
import loginLogo from '../images/login_logo.png';
import apiService from '../services/api';

const Login = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [collapseHeader, setCollapseHeader] = useState(true);
    const [otpRequired, setOtpRequired] = useState(false);
    const [otp, setOtp] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            // Get device token from localStorage if it exists
            const deviceToken = localStorage.getItem('deviceToken');
            
            const response = await apiService.login(username, password, deviceToken);

            if (response.requiresOTP) {
                setOtpRequired(true);
                setIsLoading(false);
                return;
            }

            handleLoginSuccess(response);
        } catch (err) {
            console.error('Login error:', err);
            setError(err.response?.data?.error || 'An error occurred during login');
            setIsLoading(false);
        }
    };

    const handleOTPSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            const response = await apiService.verifyOTP(username, otp);
            handleLoginSuccess(response);
        } catch (err) {
            console.error('OTP verification error:', err);
            setError(err.response?.data?.error || 'Invalid OTP');
            setIsLoading(false);
        }
    };

    const handleLoginSuccess = (data) => {
        sessionStorage.setItem('token', data.token);
        sessionStorage.setItem('collapseHeader', collapseHeader);
        if (data.deviceToken) {
            localStorage.setItem('deviceToken', data.deviceToken);
        }
        onLogin(data.token, collapseHeader);
    };

    // Add OTP input field to the form when required
    const renderOTPForm = () => (
        <div className="mb-4">
            <label htmlFor="otp" className="block text-sm font-medium text-[#e0e0e0] mb-2">
                Verification
            </label>
            <input
                type="text"
                id="otp"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="input-base w-full px-3 py-2 text-white rounded-md focus:outline-none text-sm sm:text-base [text-security:disc] [-webkit-text-security:disc]"
                disabled={isLoading}
                maxLength={6}
                autoComplete="off"
            />
        </div>
    );

    return (
        <div className="flex items-center justify-center min-h-screen app-bg px-4 py-8 font-sans">
            <form onSubmit={otpRequired ? handleOTPSubmit : handleSubmit} className="surface-bg p-6 sm:p-8 rounded-lg shadow-md w-full max-w-md border border-[#2a2a2a]">
                <div className="flex flex-col items-center mb-6">
                    <img src={loginLogo} alt="Login Logo" className="w-24 sm:w-32 h-auto mb-4" />
                    <h2 className="text-xl sm:text-2xl font-bold text-white">Login</h2>
                </div>
                {error && <p className="text-red-500 mb-4 text-sm sm:text-base">{error}</p>}
                {otpRequired ? (
                    <>
                        {renderOTPForm()}
                        <button
                            type="submit"
                            className="primary-button w-full p-2 text-white rounded-md disabled:opacity-50 flex items-center justify-center focus:outline-none focus:ring-1 focus:ring-white flash-button text-sm sm:text-base"
                            disabled={isLoading}
                        >
                            <span className="material-symbols-outlined text-lg sm:text-xl">verified_user</span>
                            <span className="font-medium">Verify</span>
                        </button>
                    </>
                ) : (
                    <>
                        <div className="mb-4">
                            <label htmlFor="username" className="block text-sm font-medium text-[#e0e0e0] mb-2">
                                Username
                            </label>
                            <input
                                type="text"
                                id="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                className="input-base w-full px-3 py-2 text-white rounded-md text-sm sm:text-base [text-security:disc] [-webkit-text-security:disc]"
                                disabled={isLoading}
                                autoComplete="off"
                            />
                        </div>
                        <div className="mb-4">
                            <label htmlFor="password" className="block text-sm font-medium text-[#e0e0e0] mb-2">
                                Password
                            </label>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="input-base w-full px-3 py-2 text-white rounded-md text-sm sm:text-base [text-security:disc] [-webkit-text-security:disc]"
                                disabled={isLoading}
                            />
                        </div>
                        <div className="flex items-center justify-between mb-4">
                            <label htmlFor="collapseHeader" className="text-xs sm:text-sm font-medium text-[#e0e0e0] mr-2">Collapse Header</label>
                            <div
                                className={`w-10 sm:w-12 h-5 sm:h-6 flex items-center rounded-full p-1 cursor-pointer border flash-button ${
                                    collapseHeader 
                                        ? 'bg-[#1976d2] border-[#2196f3]' 
                                        : 'base-surface border-[#424242]'
                                }`}
                                onClick={() => setCollapseHeader(!collapseHeader)}
                            >

                                <div
                                    className={`bg-white border surface-border w-3 sm:w-4 h-3 sm:h-4 rounded-full shadow-md  transform transition-transform duration-300 ease-in-out ${collapseHeader ? 'translate-x-5 sm:translate-x-6' : ''}`}
                                ></div>
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="primary-button w-full p-2 text-white rounded-md disabled:opacity-50 flex items-center justify-center focus:outline-none focus:ring-1 focus:ring-white flash-button text-sm sm:text-base"
                            disabled={isLoading}
                        >
                            <span className="material-icons text-lg sm:text-xl mr-1">login</span>
                            <span className="font-medium">
                                Login
                            </span>
                        </button>
                    </>
                )}
            </form>
        </div>
    );
}

export default Login;