import React from 'react';

const getDragStyles = (item, draggedItem, draggedOverItem, items) => {
    if (draggedItem && draggedItem.id === item.id) {
        return "opacity-50";
    }
    if (draggedOverItem && draggedOverItem.id === item.id) {
        const draggedIndex = items.findIndex(n => n.id === draggedItem.id);
        const targetIndex = items.findIndex(n => n.id === item.id);
        if (draggedIndex < targetIndex) {
            return "border-b-2 border-blue-500";
        } else {
            return "border-t-2 border-blue-500";
        }
    }
    return "";
};

function DraggableList({
    items,
    selectedItem,
    onItemSelect,
    onItemDelete,
    draggedItem,
    setDraggedItem,
    draggedOverItem,
    setDraggedOverItem,
    onDrop,
    deleteConfirmation,
    setDeleteConfirmation,
    isPanelCollapsed,
    renderItemContent,
    className = "space-y-2 px-4"
}) {
    if (isPanelCollapsed) return null;

    const handleDragStart = (e, item) => {
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text/plain', item.id);
        setDraggedItem(item);
        setTimeout(() => {
            e.target.style.opacity = '0.5';
        }, 0);
    };

    const handleDragOver = (e, item) => {
        e.preventDefault();
        setDraggedOverItem(item);
    };

    const handleDragEnd = (e) => {
        e.target.style.opacity = '1';
        setDraggedItem(null);
        setDraggedOverItem(null);
    };

    const handleDelete = (e, item) => {
        e.stopPropagation();
        const currentIndex = items.findIndex(i => i.id === item.id);
        const nextItem = items[currentIndex + 1] || items[currentIndex - 1];
        onItemDelete(item.id);
        if (nextItem) {
            onItemSelect(nextItem);
        }
    };

    return (
        <ul className={className}>
            {items.map((item) => (
                <li
                    key={item.id}
                    draggable
                    onDragStart={(e) => handleDragStart(e, item)}
                    onDragOver={(e) => handleDragOver(e, item)}
                    onDragEnd={handleDragEnd}
                    onDrop={(e) => onDrop(e, item)}
                    className={`p-2 rounded cursor-pointer hover:bg-[#2a2a2a] hover:bg-opacity-50 transition duration-150 ease-in-out active:bg-opacity-80 active:bg-[#3a3a3a]
                        ${selectedItem && selectedItem.id === item.id ? 'bg-[#2a2a2a] bg-opacity-50 shadow-md' : ''}
                        ${getDragStyles(item, draggedItem, draggedOverItem, items)}`}
                    onClick={() => onItemSelect(item)}
                >
                    <div className="flex justify-between items-center">
                        {renderItemContent(item)}
                        <div className="flex-shrink-0 ml-2">
                            {deleteConfirmation === item.id ? (
                                <div className="flex items-center space-x-2">
                                    <button
                                        onClick={(e) => handleDelete(e, item)}
                                        className="text-red-400 hover:text-red-300 transition duration-150 ease-in-out"
                                    >
                                        <span className="material-icons text-sm">check</span>
                                    </button>
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setDeleteConfirmation(null);
                                        }}
                                        className="text-[#ababab] hover:text-[#e0e0e0] transition duration-150 ease-in-out"
                                    >
                                        <span className="material-icons text-sm">close</span>
                                    </button>
                                </div>
                            ) : (
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setDeleteConfirmation(item.id);
                                    }}
                                    className="text-red-400 hover:text-red-300 transition duration-150 ease-in-out"
                                >
                                    <span className="material-symbols-outlined text-sm">delete</span>
                                </button>
                            )}
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    );
}

export default DraggableList; 