import React from 'react';
import DocumentEditorHeader from './DocumentEditorHeader';
import DotPhraseTextArea from '../NoteEditor/DotPhraseTextArea';
import ChatBox from './ChatBox';

function DocumentEditorContent({ document, onUpdateDocument, currentPreset, handlePresetChange }) {
    return (
        <div className="surface-bg shadow-lg rounded-lg border border-[#2a2a2a]">
            <div className="p-4 sm:p-6 flex flex-col">
                <DocumentEditorHeader
                    document={document}
                    onUpdateDocument={onUpdateDocument}
                    currentPreset={currentPreset}
                    handlePresetChange={handlePresetChange}
                    className="mb-6"
                />
                <div className="mb-6">
                    <span 
                        className="px-3 py-1.5 text-sm font-medium base-surface surface-border text-white rounded-t-md"
                    >
                        Title
                    </span>
                    <div className="relative -mt-[1px]">
                        <input
                            id="title"
                            type="text"
                            className="block w-full rounded-md input-base transition duration-150 ease-in-out shadow-sm px-3 py-2"
                            style={{
                                borderTopLeftRadius: '0',
                            }}
                            value={document.title === "Untitled Document" ? "" : document.title || ""}
                            placeholder={document.title === "Untitled Document" ? "Untitled Document" : ""}
                            onChange={(e) => onUpdateDocument({ ...document, title: e.target.value })}
                        />
                    </div>
                </div>
                <div className="mb-4">
                    <span 
                        className="px-3 py-1.5 text-sm font-medium base-surface surface-border text-white rounded-t-md"
                    >
                        Document
                    </span>
                    <DotPhraseTextArea
                        id="content"
                        className="block w-full rounded-md input-base transition duration-150 ease-in-out shadow-sm px-3 py-2 overflow-hidden"
                        value={document.content || ''}
                        onChange={(e) => onUpdateDocument({ ...document, content: e.target.value })}
                    />
                </div>
            </div>
            <ChatBox document={document} onUpdateDocument={onUpdateDocument} />
        </div>
    );
}

export default DocumentEditorContent; 