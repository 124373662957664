import React, { useRef, useEffect } from 'react';

function BaseSidebar({
    children,
    isPanelCollapsed,
    setIsPanelCollapsed,
    isSearchFocused = false,
    className = '',
    title = '',
}) {
    const sidebarRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && 
                !sidebarRef.current.contains(event.target) && 
                !isPanelCollapsed && 
                !isSearchFocused) {
                setIsPanelCollapsed(true);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isPanelCollapsed, isSearchFocused, setIsPanelCollapsed]);

    return (
        <div
            ref={sidebarRef}
            className={`sidebar-container ${
                isPanelCollapsed && !isSearchFocused 
                    ? 'sidebar-collapsed-width' 
                    : 'sidebar-expanded-width'
            } ${className}`}
        >
            <div className="p-4 h-full flex flex-col">
                <div className="flex justify-between items-center mb-4">
                    {!isPanelCollapsed && <h2 className="text-xl font-semibold text-gray-100">{title}</h2>}
                    <button
                        onClick={() => setIsPanelCollapsed(!isPanelCollapsed)}
                        className="text-[#ababab] hover:bg-[#2a2a2a] hover:bg-opacity-50 p-2 rounded transition duration-150 ease-in-out flash-button"
                    >
                        <span className="material-icons">
                            {isPanelCollapsed ? 'chevron_right' : 'chevron_left'}
                        </span>
                    </button>
                </div>
                {children}
            </div>
        </div>
    );
}

export default BaseSidebar; 